.OrderEditor :global(.row) {
  margin-top: 20px;
}

.OrderEditor .Additional {
  background: rgba(30, 95, 132, 0.06);
  margin-top: 0;
  padding-top: 20px;
}

.OrderEditor :global(.alert) {
  margin-top: 10px;
  margin-bottom: 0;
}

.Counter input {
  text-align: center;
}

.Counter :global(.input-group-prepend) {
  flex-basis: 50%;
}

.Counter :global(.input-group-prepend) span {
  flex-grow: 1;
}

.Counter input::-webkit-outer-spin-button,
.Counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Counter input[type=number] {
  -moz-appearance: textfield;
}

.Confirm {
  text-align: center;
}

.Confirm .Cancel {
  color: red;
  display: block;
  margin: 20px auto;
}

.Location, .Location * {
  cursor: pointer;
}

.OrderEditor textarea {
  height: 130px;
}
