.Info  div {
  color: rgba(0, 0, 0, 0.6);
}

.Orders :global(.btn) {
  display: block;
  margin: 1em auto;
  max-width: 300px;
  text-transform: uppercase;
}

.Logout {
  margin-top: 1em;
  text-align: center;
}

.Logout * {
  color: red;
  text-transform: uppercase;
}
