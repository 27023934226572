.Order:nth-child(even) {
  background-color: rgba(30, 95, 132, 0.1);
}

.Date {
  font-size: 16px;
  margin: 0 16px;
  padding: 12px 0;
}

.Complete {
  background: #454545;
  color: white;
  font-size: 18px;
  line-height: 56px;
  text-align: center;
}

.Waiting {
  background: #ead271;
  color: #3e3e3e;
  font-size: 18px;
  line-height: 56px;
  text-align: center;
}

.OrderDetails .DetailsBox {
  display: inline-block;
  margin: 10px;
  vertical-align: middle;
}

.Cancel {
  padding: 10px;
  text-align: center;
}

.Cancel :global(.alert) {
  margin-top: 10px;
}

.Reorder {
  padding: 10px;
  text-align: center;
}
