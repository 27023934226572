#Container {
  height: 440px;
  max-height: 100vh;
  width: 100%;
}

#Search {
  height: 0;
  position: relative;
  text-align: center;
}

#Search input {
  margin: 0 auto;
  position: relative;
  top: -45px;
  width: 50%;
}

#Search .Suggestions {
  width: 50%;
}
