#Navi {
  background: #1e5f84;
  font-weight: 500;
  letter-spacing: 0.6px;
}

#Navi :global(.nav-link) {
  text-transform: uppercase;
}

#Navi :global(.nav-link.active) {
  text-decoration: underline;
}

#Navi .CustomerService {
  font-weight: normal;
  text-transform: none;
}

#Navi .Order {
  background-color: white;
  border-radius: 50px;
  color: #1e5f84;
}
